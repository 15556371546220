@mixin hoverStates($include-parent: true) {
    @if $include-parent == true {
        & {
            @content;
        }
    }
    &:focus,
    &:active,
    &:hover,
    &:active:hover,
    &:active:focus {
        @content;
    }
}
@mixin hoverStatesNoParent() {
    @include hoverStates(false) {
        @content;
    }
}
@mixin placeholder() {
    &::-webkit-input-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
}
