@import "../../../../backend/assets/styles/variables";
@import "../../../../backend/assets/styles/mixins";

button {
    @include hoverStates {
        position: relative;
        z-index: 1;
        display: inline-block;
        vertical-align: top;
        width: auto;
        text-align: center;
        color: map_get($colors, light);
        border-radius: 3px;
        border: 1px solid map_get($colors, button);
        margin: 0;
        outline: 0;
        padding: 0 20px;
        font-weight: bold;
        font-size: 1.1em;
        line-height: 45px;
        overflow: hidden;
        background: map_get($colors, button);
        cursor: pointer;
        text-decoration: none;
        top: 0;
        user-select: none;
        box-shadow: none;
        transition: all 0.3s, width 0.001s;

        &[readonly],
        &[disabled] {
            opacity: 1;
            background: mix(map_get($colors, border), map_get($colors, background), 25%);
            border-color: mix(map_get($colors, border), map_get($colors, background), 75%);
            color: mix(map_get($colors, border), map_get($colors, text), 25%);
            cursor: not-allowed;
            box-shadow: none;
        }

        &.btn-block {
            display: block;
            width: 100%;

            &-half {
                width: 49%;

                &:first-child {
                    margin-right: 2%;
                }
            }
        }

        &.btn-small {
            line-height: 34px;
            font-size: 0.9em;
            padding: 0 14px;
        }

        &.btn-large {
            line-height: 60px;
        }

        &.btn-danger {
            background: map-get($colors, error);
            border-color: map-get($colors, error);
        }

        &.btn-blue {
            background: map-get($colors, link);
            border-color: map-get($colors, link);
        }

        &.btn-outline {
            color: map-get($colors, secondary);
            border-color: map-get($colors, border);
            background: map-get($colors, light);
        }
    }

    @include hoverStatesNoParent {
        background: map-get($colors, button_active);
        border-color: map-get($colors, button_active);

        &.btn-danger {
            background: darken(map-get($colors, error), 7%);
            border-color: darken(map-get($colors, error), 7%);
        }

        &.btn-blue {
            background: map-get($colors, link_active);
            border-color: map-get($colors, link_active);
        }

        &.btn-outline {
            color: map-get($colors, light);
            border-color: map-get($colors, secondary);
            background: map-get($colors, secondary);
        }
    }
}

.btn:not(.btn-block) + .btn:not(.btn-block) {
    margin-left: 10px;
}

input[type="text"],
input[type="number"],
input[type="search"],
input[type="color"],
input[type="password"],
input[type="email"],
input[type="file"],
textarea,
select {
    @include hoverStates {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 1px solid map_get($colors, border);
        border-radius: 3px;
        padding: 0.6rem 1rem;
        height: 2.8125rem;
        line-height: inherit;
        font-size: 1rem;
        font-family: inherit;
        color: map_get($colors, text);
        background: #fff;
        box-shadow: none;
        font-weight: 600;
        outline: none;
        width: 100%;
        transition: all 0.3s;

        &[readonly],
        &[disabled] {
            opacity: 1;
            background: map_get($colors, background);
            border-color: mix(map_get($colors, border), map_get($colors, background), 75%);
            color: mix(map_get($colors, border), map_get($colors, text), 75%);
            cursor: not-allowed;
            box-shadow: none;
        }
    }

    @include placeholder {
        font-style: normal;
        font-weight: 400;
        color: lighten(map_get($colors, text), 30%);
    }

    @include hoverStatesNoParent {
        border-color: map_get($colors, text);
    }
}

textarea.form-control {
    height: 10.8125rem;
}

input[type="number"] {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

input[type="number"] {
    -moz-appearance: textfield;
}
