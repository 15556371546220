@import "../../../backend/assets/styles/_mixins";
@import "../../../backend/assets/styles/_variables";
@import "styles/vendor/balloon";
@import "ngx-smart-modal/ngx-smart-modal";

// Reset
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    font-size: 16px;
    line-height: 1.6;
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    color: map-get($colors, text);

    &,
    body {
        width: 100%;
        height: 100%;
        min-width: 1280px;
        background: map-get($colors, background);

        // SNOEI-283 - Disable (pinch) zoom
        -ms-content-zooming: none;
        touch-action: manipulation;
    }
    &.page-clean {
        &,
        body {
            background: #fff;
        }
    }
    h1 {
        font-size: 1.8em;
        line-height: 0.9;
        color: map_get($colors, secondary);
    }
    hr {
        border: 0;
        height: 1px;
        display: block;
        background: map_get($colors, border);
    }
    iframe {
        border: 0;
    }
    a {
        display: inline-block;
        vertical-align: top;
        text-decoration: none;
        color: map_get($colors, link);
        font-weight: 600;
        cursor: pointer;

        &.link-primary {
            color: map_get($colors, primary);
        }
        &.link-add {
            color: map_get($colors, primary);
            font-weight: bold;

            .icon {
                display: inline-block;
                vertical-align: top;
                background: map_get($colors, primary);
                color: #fff;
                border-radius: 50%;
                width: 1.8em;
                height: 1.8em;
                line-height: 1.8em;
                text-align: center;
                font-size: 0.7em;
                margin: 0.2em 0.2em 0 0;
            }
            &:hover {
                .icon {
                    background-color: map_get($colors, link_active);
                }
            }
        }
        &:hover {
            color: map_get($colors, link_active);
        }
    }

    @import "styles/inputs";
}

app-root {
    display: block;
}

.table {
    margin: 50px;
    display: table;
    width: calc(100% - 50px);

    .left {
        display: table-cell;
        width: 40%;
    }
    .right {
        text-align: right;
        display: table-cell;
        width: 60%;
        padding-right: 50px;
    }
    .hidden {
        display:none;
    }
}

.padded {
    margin: 50px;
}

.order-status-new {
    font-weight: bold;
    color: map_get($colors_secondary, assertive);
}

.order-status-canceled {
    font-weight: bold;
    color: map_get($colors, border);
}

.order-status-ordered, .order-status-processed {
    font-weight: bold;
    color: map_get($colors, primary);
}

.order-status-inprogress {
    font-weight: bold;
    color: map_get($colors_secondary, positive);
}

.clickable {
    cursor: pointer;
    user-select: none;

    @include hoverStates {
        outline: none;
    }
    @include hoverStatesNoParent {
        &,
        & > td {
            background-color: map_get($colors, background);
        }
    }
}

.highlight {
    display: inline-block;
    vertical-align: top;
    @extend .text-highlight;
}
.text-highlight {
    color: map_get($colors, secondary);
    font-weight: bold;
}
.text-light {
    color: map_get($colors, border);
    font-weight: 300;
}

form {
    .form-group {
        margin: 0 0 10px 0;

        .form-label {
            display: block;
            float: left;
            width: 180px;
            padding: 11px 0;
            font-weight: bold;

            &.small-label {
                width: 100px;
            }
        }
        > .form-control,
        .input-group {
            display: block;
            float: left;

            @include hoverStates {
                width: calc(100% - 180px);

                &.small-label {
                    width: calc(100% - 100px);
                }
            }
            &.no-label {
                @include hoverStates {
                    width: 100%;
                }
            }
        }
        .invalid-feedback,
        .invalid-feedback-block {
            font-size: 0.8rem;
            color: map_get($colors, error);
            font-weight: bold;
            margin: 0.1rem 0 0 0;
        }
        .invalid-feedback {
            clear: both;
            float: left;
            display: none;
            padding: 0 0 0 180px;
            width: 100%;
        }
        .form-control {
            &.is-invalid ~ .invalid-feedback {
                display: block;
            }
            &:not(:first-child) {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
            &:not(:last-child) {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
        .invalid-feedback-block {

        }
        .form-control-tiny {
            @include hoverStates {
                width: 100px;
            }
        }
        .input-group {
            padding: 0;
            margin: 0;
            display: flex;
        }
        .input-group-prepend {
            border: 1px solid map_get($colors, border);
            border-right: 0;
            border-radius: 3px 0 0 3px;
        }
        .input-group-append {
            border: 1px solid map_get($colors, border);
            border-left: 0;
            border-radius: 0 3px 3px 0;
        }
        .input-group-text {
            min-width: 43px;
            text-align: center;
            line-height: 43px;
            font-weight: bold;
            background: map_get($colors, light);
            padding: 0 12px;
            color: rgba(map_get($colors, text), 0.6);
        }
        .input-group-note {
            line-height: 45px;
            font-size: 0.8rem;
            padding: 0 14px;
            color: rgba(map_get($colors, text), 0.6);
        }
        &:after {
            @include clearfix();
        }
    }
}

table {
    table-layout: fixed;
    border: 0;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    th,
    td {
        text-align: left;
        vertical-align: top;
        padding: 20px 0 20px 30px;

        &:last-child {
            padding-right: 30px;
        }
    }
    th {
        font-weight: bold;
    }
    td {
        border-top: 1px solid map_get($colors, border);
    }
    &.border-bottom {
        border-bottom: 1px solid map_get($colors, border);

        & + .border-bottom {
            margin: 20px 0 0 0;
        }
    }
}
.boxed-table-wrapper {
    position: relative;
    margin: -30px;
}

app-box {
    hr {
        display: block;
        border: 0;
        border-bottom: 1px solid map_get($colors, border);
        margin: 30px -30px;
        padding: 0;
        height: 0;
    }
}

#toast-container {
    font-size: 0.9em;

    &.notification-bottom {
        display: block;
        position: fixed;
        z-index: 999999;
        bottom: 0;
        left: 50%;
        width: 100%;
        max-width: 500px;
        transform: translateX(-50%);
        contain: none;
        overflow: visible;
    }
    .notification-success,
    .notification-error {
        text-align: left;
        font-weight: bold;
        display: block;
        position: absolute;
        z-index: 999999;
        bottom: 1em;
        left: 1em;
        right: 1em;
        margin: 0;
        padding: 1.2em 1.4em;
        line-height: 1.2em;
        border-radius: 0.2em;
        box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.1);
        animation-name: show-notification;
        animation-duration: 0.6s;
        animation-delay: 0s;
        animation-iteration-count: 1;
        animation-direction: normal;
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;
    }
    .notification-success {
        color: #fff;
        background: #111;

        i.icon {
            color:  #111;
        }
    }
    .notification-error {
        color: #fff;
        background: map_get($colors, error);

        i.icon {
            color: map_get($colors, error);
        }
    }
    .notification-has-icon {
        padding-left: 4em;
    }
    i.icon {
        position: absolute;
        top: 0.8em;
        left: 1em;
        font-size: inherit;
        width: 2em;
        height: 2em;
        line-height: 2em;
        text-align: center;
        background: #fff;
        border-radius: 50%;
    }
    small {
        display: inline-block;
        vertical-align: top;
        background: lighten(map_get($colors, error), 10);
        font-weight: bold;
        font-size: 0.6em;
        padding: 0.5em 0.8em;
        line-height: 1.2em;
        border-radius: 0.3em;
        margin: -0.1em 0 -0.1em 1.5em;

        &::before {
            content: 'CODE: ';
        }
    }
    .toast-close-button {
        display: block;
        float: right;
        font-size: 1.4em;
        width: 1.4em;
        height: 1.4em;
        line-height: 1.4em;
        text-align: center;
        margin: -0.3em -0.45em -0.3em 1em;
        background: none;
        border-radius: 50%;
        color: inherit;
        padding: 0;
        border: 1px solid #fff;

        &:hover,
        &:focus {
            background-color: lighten(map_get($colors, error), 10);
        }
    }
    &::after {
        @include clearfix();
    }
}
@keyframes show-notification {
    from {
        transform: translateY(-1.2em);
    }
    to {
        transform: translateY(0);
    }
}

.overlay.nsm-overlay-open {
    background-color: rgba(0,0,0,0.8);
}
.global-modal {
    max-width: none;

    .nsm-content {
        @include positionCenter();
        position: fixed;
        display: block;
        padding: 0;
        margin: 0;
        min-width: 400px;
        width: 100vw;
        max-width: 600px;
        border-radius: 0.6em;
        box-shadow: $app-shadow;

        .modal-title {
            padding: 25px 30px;
            border-bottom: 1px solid map_get($colors, border);
            color: map_get($colors, secondary);
            font-weight: bold;
            font-size: 1.4em;
            line-height: 1;

            .text-primary {
                color: map_get($colors, primary);
            }
        }
        .modal-template,
        .modal-content {
            padding: 30px;
        }
        .modal-footer {
            border-top: 1px solid map_get($colors, border);
            padding: 30px;
        }
    }
    .nsm-dialog-btn-close {
        @include hoverStates {
            position: absolute;
            top: -52px;
            right: 0;
            background: transparent no-repeat center url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiAxNiI+ICA8cGF0aCBkPSJNOC45MSw4bDYuOS02LjlBLjY0LjY0LDAsMCwwLDE0LjkuMTlMOCw3LjA5LDEuMS4xOWEuNjQuNjQsMCwwLDAtLjkxLjkxTDcuMDksOCwuMTksMTQuOWEuNjUuNjUsMCwwLDAsMCwuOTFBLjYzLjYzLDAsMCwwLC42NSwxNmEuNjEuNjEsMCwwLDAsLjQ1LS4xOUw4LDguOTFsNi45LDYuOWEuNjEuNjEsMCwwLDAsLjQ1LjE5LjYzLjYzLDAsMCwwLC40Ni0uMTkuNjUuNjUsMCwwLDAsMC0uOTFaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwKSIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==');
            background-size: 100% 100%;
            padding: 0;
            margin: 0;
            width: 26px;
            height: 26px;
            color: #fff;
            border: 0;
            transition: all 0.3s;

            svg {
                display: none;
            }
        }
        @include hoverStatesNoParent() {
            opacity: 0.3;
        }
    }
}
.custom-modal-prompt {
    .nsm-content {
        min-width: 300px;
        max-width: 300px;
    }
}

.hidden {
    display: none !important;
}

.loading-spinner-inline {
    display: inline-block;
    vertical-align: baseline;
    width: 20px;
    height: 20px;
    margin: 0 6px 0 0;
    border-radius: 50%;
    border: 3px solid;
    border-color: currentColor currentColor currentColor transparent;
    animation-name: loading-spinner-inline;
    animation-duration: 0.8s;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}
@keyframes loading-spinner-inline {
    0% {
        transform: translateY(3px) rotate(0deg);
    }
    50% {
        transform: translateY(3px) rotate(180deg);
    }
    100% {
        transform: translateY(3px) rotate(360deg);
    }
}

.form-submit-error {
    animation-name: shake;
    animation-duration: 1s;
    animation-fill-mode: both;
}
@keyframes shake {
    from,
    to {
        transform: translate3d(0, 0, 0);
    }
    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translate3d(-10px, 0, 0);
    }
    20%,
    40%,
    60%,
    80% {
        transform: translate3d(10px, 0, 0);
    }
}

.header-contact {
    display: inline-block;
    vertical-align: top;
    margin: 0 2em 0 0;
    font-weight: bold;
    color: map_get($colors, secondary);

    a {
        text-decoration: none;
        color: inherit;
    }
    .icon {
        color: map_get($colors, primary);
        font-size: 1.4rem;
        margin: 0 0.3rem 0 0;
    }
}

.page-dealer-main {
    position: relative;
    z-index: 1;
    display: flex;
    min-height: calc(100vh - #{$app-header-height});

    &__section {
        position: relative;
        z-index: 1;
        flex: 1;
    }
    &__aside {
        position: relative;
        z-index: 10;
        flex: 1;
        max-width: 300px;
        background: #fff;
        border-right: 1px solid map_get($colors, border);
    }
}

.hide-1500 {
    @media (max-width: 1500px) {
        display: none;
    }
}

.hide-1700 {
    @media (max-width: 1700px) {
        display: none;
    }
}

.blueprint-preview {
    width: 600px;
    display: block;
    margin: 30px -30px -30px -30px;
}
